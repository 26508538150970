
import megaMenuMixin from '~/mixins/megaMenu'

export default {
  name: 'MegaMenuSheetDesktop',
  mixins: [megaMenuMixin],

  props: {
    navbarId: {
      type: Number,
      required: true,
    },

    isHomepage: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    activeItem: {},
  }),

  computed: {
    root () {
      return this.navbarItems?.[0] || null
    },

    isExpanded () {
      return this.activeItem?.children?.length > 0
    },
  },

  created () {
    this.activeItem = this.isHomepage ? {} : this.navbarItems?.[0]?.children?.[0]
  },

  methods: {
    setActiveItem (node) {
      this.activeItem = node
    },

    clearParentItem () {
      if (this.isHomepage) {
        this.activeItem = null
      }
    },

    onMenuClick () {
      this.clearParentItem()
      this.$emit('toggle-navbar', false)
    },
  },
}
