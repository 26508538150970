
import megaMenuMixin from '~/mixins/megaMenu'

export default {
  name: 'MegaMenuSheetDesktopItem',

  mixins: [
    megaMenuMixin,
  ],

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  computed: {
    image () {
      return this.item.image || this.item.icon
    },

    imgAsIcon () {
      if (['custom-url-text-icon'].includes(this.item.classes)) {
        return true
      }

      if (this.item.type === 'custom_url') {
        return false
      }

      return this.item.title
    },

    showText () {
      if (['custom-url-img'].includes(this.item.classes)) {
        return false
      }
      return !!this.item.title
    },
  },
}
